<template>
  <v-content>
    <v-container class="wrapper">
      <v-row align="center" justify="center">
        <h1>Privacy Policy</h1>
      </v-row>
      <v-row align="center" justify="center" class="form-wrapper">
        <v-col cols="12" sm="10" md="8">
          <div>
            <article id="content">
              <p>
                YouTube Repeater (“we” or “us”) is committed to protecting your
                privacy. This Privacy Policy explains the methods and reasons we
                collect, use, disclose, transfer, and store your information. If
                you have any questions about the contents of this policy, don’t
                hesitate to contact us.
              </p>

              <p>
                If you do not consent to the collection and use of information
                from or about you in accordance with this Privacy Policy, then
                you are not permitted to use Tubelooper or any services provided
                on
                <a href="https://youtube-repeater.com"
                  >https://youtube-repeater.com</a
                >.
              </p>

              <p></p>
              <h3>Applicable Law</h3>
              YouTube Repeater is headquartered in Belgrade, Serbia. By viewing
              any content or otherwise using the services offered by YouTube
              Repeater, you consent to the transfer of information to Serbia to
              the extent applicable, and the collection, storage, and processing
              of information under Serbian law.
              <p></p>

              <p></p>
              <h3>Information We Collect</h3>
              Information you Submit: We store information you provide on this
              site via forms, surveys, or any other interactive content. This
              information commonly includes, but is not limited to, name,
              mailing address, email address, and uploaded files.
              <p></p>

              <p>
                <strong>Log Files</strong>: We collect information when you use
                services provided on our site. This information may include your
                IP address, device and software characteristics (such as type
                and operating system), page views, referral URLs, device
                identifiers or other unique identifiers such as advertising
                identifiers (e.g., “ad-ID” or “IDFA”), and carrier information.
                Log files are primarily used for the purpose of enhancing the
                user experience.
              </p>

              <p>
                <strong>Cookies</strong>: We use cookies and related
                technologies to keep track of user preferences and activity.
                Cookies are small text files created by a web server, delivered
                through a web browser, and stored on your computer. Most
                Internet browsers automatically accept cookies. You can instruct
                your browser, by changing its settings, to stop accepting
                cookies or to prompt you before accepting a cookie from the
                websites you visit.
              </p>
              <h3>Third Party Services</h3>
              This site contains links to other websites not owned by YouTube
              Repeater. In general, the third-party services used by us will
              only collect, use and disclose your information to the extent
              necessary to allow them to perform their intended services. Please
              be aware that we are not responsible for the privacy policies of
              third-party services. Below is a list of third-party services used
              on this site.
              <h3>Children and COPPA</h3>
              YouTube Repeater is committed to complying with the Children’s
              Online Privacy Protection Act (COPPA). We do not use our services
              to intentionally solicit data from or market to children under the
              age of 13. We encourage parents and guardians to report any
              suspicions that a child has provided us with information without
              their consent. We will take reasonable action to remove this
              information.
              <h3>Your Choices</h3>
              Opt-Out of Third Party Advertisement Cookies: You may opt out of a
              third-party vendor’s use of cookies for interest based advertising
              by visiting aboutads.info. Do Not Track Browser settings: If you
              enable Do Not Track settings in the browser you are using, YouTube
              Repeater will not use information about websites you visit, other
              than our site. Learn more about Do Not Track by visiting
              allaboutdnt.com Email Communication Opt-Out If you receive
              promotional emails from YouTube Repeater, you can unsubscribe by
              clicking the bottom Unsubscribe or Opt-Out link at the bottom of
              every email.
              <h3>Contact Us</h3>
              At YouTube Repeater, we believe our talented customer service
              staff will be able to resolve any issues you may have using the
              our services. If you would like additional information about this
              privacy policy, please visit our homepage at
              <a href="https://youtube-repeater.com"
                >https://youtube-repeater.com</a
              >.
              <p></p>
            </article>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>
<script>
export default {
  name: 'app-privacy',
};
</script>
<style lang="sass" scoped>
.wrapper
  max-width: 1400px
  margin: 0 auto
  +flex-ctr()
  flex-direction: column
  >div
    flex-direction: column
</style>
